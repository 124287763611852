<template>
  <div>
    <b-icon @click="showModal = true" icon="wallet" style="cursor: pointer;"></b-icon> <span @click="showModal = true" style="cursor: pointer;">Valuation Tool</span>
    <b-modal
      v-model="showModal"
      size="xl"
      @hidden="resetForms(true)"
      hide-footer
    >
      <b-row class="mb-3">
        <b-col></b-col>
        <b-col class="text-center">
          <template v-if="!vehicleRegLocked">
            <b-input-group>
              <b-form-input
                id="vehicle-reg"
                class="carReg"
                v-model="vehicleReg"
                trim
                @keydown.space.prevent
                @keydown.enter="vehicleRegFind"
                placeholder="Registration">
              </b-form-input>
              <b-button
                id="searchButton"
                variant="outline-primary"
                @click="vehicleRegFind"><b-icon icon="search"></b-icon></b-button>
            </b-input-group>
          </template>
          <template v-else>
            <h2>
              <b-badge variant="primary" class="carReg">{{ vehicleReg }}</b-badge>
            </h2>
            <b-badge v-if="regLookupInvalidReg" variant="danger">Vehicle registration not recognised</b-badge>
          </template>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="vehicleRegLocked"
            @click="vehicleRegNewSearch"
            pill
            variant="outline-primary"
            size="sm"
            style="margin-top: 5px;"><b-icon icon="search"></b-icon> New Search</b-button>
        </b-col>
      </b-row>

      <div class="accordion" role="tablist">

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              variant="outline-primary">Valuation</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" v-model="accordianPrice" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>

                <b-row>
                  <b-col>
                    <template v-if="vehicleValuation && !vehicleValuation.unavailable">
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">OTR</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.OTR }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Forecourt</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.DealerForecourt }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">P/X</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.PartExchange }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Auction</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.Auction }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Private (Clean)</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.PrivateClean }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Private (Average)</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.PrivateAverage }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Retail)</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradeRetail }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Average)</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradeAverage }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Poor)</label>
                        </b-col>
                        <b-col>
                          <b-button pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradePoor }}</b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-badge v-else-if="vehicleValuation && vehicleValuation.unavailable" variant="warning">Valuations unavailable</b-badge>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>
    </b-modal>

  </div>
</template>

<script>
import firebase from 'firebase'
import { mapActions } from 'vuex'

export default {
  name: 'ValuationTool',
  data() {
    return {
      showModal: false,
      accordianPrice: true,

      timestampFormat: 'YYYY-MM-DDTHH:mm:ss',

      regLookupInvalidReg: false,

      vehicleReg: '',
      vehicleRegLocked: false,

      vehicleValuation: null,
      
    }
  },
  computed: {

  },
  watch: {
    vehicleReg() {
      if (this.vehicleReg) {
        this.vehicleReg = this.vehicleReg.toUpperCase()
        this.vehicleReg = this.vehicleReg.replace(' ', '')
      }
    },
  },
  beforeDestroy() {
    this.resetForms(true)
  },
  methods: {
    ...mapActions('stock', [
      'getVehicleValuationFromReg',
    ]),

    resetForms(clearReg) {
      if (clearReg) {
        this.vehicleReg = ''
        this.vehicleRegLocked = false
      }
      this.regLookupInvalidReg = false

      this.vehicleValuation = null
    },

    vehicleRegFind() {
      this.resetForms()
      this.vehicleRegLocked = true
      this.vehicleValuationLookup()
    },
    vehicleRegNewSearch() {
      this.accordianPrice = true // Go back to Vehicle step
      this.resetForms()
      this.vehicleRegLocked = false
      this.vehicleReg = ''
    },

    vehicleValuationLookup() {
      if (!this.vehicleValuation) {
        // Log event
        firebase.firestore().collection('logging')
        .add({
          event: 'valuationTool',
          vehicleReg: this.vehicleReg,
          timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          user: firebase.auth().currentUser.email
        })
        .then(() => {
          this.getVehicleValuationFromReg(this.vehicleReg)
            .then(response => {
              // console.log(response)
              if (response.data.Response.StatusCode === 'Success') {
                this.vehicleValuation = response.data.Response.DataItems.ValuationList
              } else if (response.data.Response.StatusCode === 'KeyInvalid') {
                this.regLookupInvalidReg = true
              } else {
                this.vehicleValuation = { unavailable: true }
              }
            })
            .catch(err => {
              console.error(err)
              this.vehicleValuation = { unavailable: true }
            })
        })
        .catch(error => {
          console.error(error)
        })
        
      }
    }

  }
}
</script>

<style scoped>
.carReg {
  font-family: 'UKNumberPlate';
  font-size: 2em;
  background-color: #FFD900;
  color: #000;
}
#vehicle-reg {
  margin-right: 10px;
}

#searchButton {
  height: 60px;
}
</style>
